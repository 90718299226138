.grid-bg {
    --color: var(--mantine-color-gray-1);
    --width: 5%;
    --scale: 20px;
    background-image: linear-gradient(var(--color) 0%, var(--color) var(--width), transparent var(--width), transparent 100%),
        linear-gradient(90deg, var(--color) 0%, var(--color) var(--width), transparent var(--width), transparent 100%);
    background-size: var(--scale) var(--scale);
    background-position: calc(var(--scale) / 2) calc(var(--scale) / 2);
}

.grid-bg-anim {
    animation: sliding-grid-anim 2s linear infinite;
}

@keyframes sliding-grid-anim {
    0% {
        background-position-x: 0px;
        background-position-y: 0px;
    }

    100% {
        background-position-x: calc(var(--scale));
        background-position-y: calc(var(--scale));
    }
}

.dots-bg {
    --color: var(--mantine-color-gray-1);
    --size: 10%;
    --scale: 30px;
    /* background-image: radial-gradient(white, var(--color)); */
    background-image: radial-gradient(var(--color) 0%, var(--color) var(--size), transparent var(--size), transparent 100%);
    background-size: var(--scale) var(--scale);
}

.sliding-color-bg {
    --width: 120px;
    --main-color: white;
    --color-1: var(--mantine-color-pink-6);
    --color-2: var(--mantine-color-yellow-5);
    background-image: linear-gradient(45deg,
            transparent 0%,
            transparent 40%,
            var(--color-1) 40%,
            var(--color-1) 50%,
            var(--color-2) 50%,
            var(--color-2) 60%,
            transparent 60%,
            transparent 100%);
    background-color: var(--main-color);
    background-repeat: no-repeat;
    background-position-x: calc(-1 * var(--width));
    /* transition: background-position 1s linear; */
    animation: sliding-bg-anim 2.6s linear infinite;
}

@keyframes sliding-bg-anim {
    0% {
        background-position-x: calc(-1 * var(--width));
    }

    50% {
        background-position-x: var(--width);
    }

    100% {
        background-position-x: var(--width);
    }
}
*, ::before, ::after {
        --tw-border-spacing-x: 0;
        --tw-border-spacing-y: 0;
        --tw-translate-x: 0;
        --tw-translate-y: 0;
        --tw-rotate: 0;
        --tw-skew-x: 0;
        --tw-skew-y: 0;
        --tw-scale-x: 1;
        --tw-scale-y: 1;
        --tw-pan-x:  ;
        --tw-pan-y:  ;
        --tw-pinch-zoom:  ;
        --tw-scroll-snap-strictness: proximity;
        --tw-gradient-from-position:  ;
        --tw-gradient-via-position:  ;
        --tw-gradient-to-position:  ;
        --tw-ordinal:  ;
        --tw-slashed-zero:  ;
        --tw-numeric-figure:  ;
        --tw-numeric-spacing:  ;
        --tw-numeric-fraction:  ;
        --tw-ring-inset:  ;
        --tw-ring-offset-width: 0px;
        --tw-ring-offset-color: #fff;
        --tw-ring-color: rgb(51 154 240 / 0.5);
        --tw-ring-offset-shadow: 0 0 #0000;
        --tw-ring-shadow: 0 0 #0000;
        --tw-shadow: 0 0 #0000;
        --tw-shadow-colored: 0 0 #0000;
        --tw-blur:  ;
        --tw-brightness:  ;
        --tw-contrast:  ;
        --tw-grayscale:  ;
        --tw-hue-rotate:  ;
        --tw-invert:  ;
        --tw-saturate:  ;
        --tw-sepia:  ;
        --tw-drop-shadow:  ;
        --tw-backdrop-blur:  ;
        --tw-backdrop-brightness:  ;
        --tw-backdrop-contrast:  ;
        --tw-backdrop-grayscale:  ;
        --tw-backdrop-hue-rotate:  ;
        --tw-backdrop-invert:  ;
        --tw-backdrop-opacity:  ;
        --tw-backdrop-saturate:  ;
        --tw-backdrop-sepia:  ;
}
::backdrop {
        --tw-border-spacing-x: 0;
        --tw-border-spacing-y: 0;
        --tw-translate-x: 0;
        --tw-translate-y: 0;
        --tw-rotate: 0;
        --tw-skew-x: 0;
        --tw-skew-y: 0;
        --tw-scale-x: 1;
        --tw-scale-y: 1;
        --tw-pan-x:  ;
        --tw-pan-y:  ;
        --tw-pinch-zoom:  ;
        --tw-scroll-snap-strictness: proximity;
        --tw-gradient-from-position:  ;
        --tw-gradient-via-position:  ;
        --tw-gradient-to-position:  ;
        --tw-ordinal:  ;
        --tw-slashed-zero:  ;
        --tw-numeric-figure:  ;
        --tw-numeric-spacing:  ;
        --tw-numeric-fraction:  ;
        --tw-ring-inset:  ;
        --tw-ring-offset-width: 0px;
        --tw-ring-offset-color: #fff;
        --tw-ring-color: rgb(51 154 240 / 0.5);
        --tw-ring-offset-shadow: 0 0 #0000;
        --tw-ring-shadow: 0 0 #0000;
        --tw-shadow: 0 0 #0000;
        --tw-shadow-colored: 0 0 #0000;
        --tw-blur:  ;
        --tw-brightness:  ;
        --tw-contrast:  ;
        --tw-grayscale:  ;
        --tw-hue-rotate:  ;
        --tw-invert:  ;
        --tw-saturate:  ;
        --tw-sepia:  ;
        --tw-drop-shadow:  ;
        --tw-backdrop-blur:  ;
        --tw-backdrop-brightness:  ;
        --tw-backdrop-contrast:  ;
        --tw-backdrop-grayscale:  ;
        --tw-backdrop-hue-rotate:  ;
        --tw-backdrop-invert:  ;
        --tw-backdrop-opacity:  ;
        --tw-backdrop-saturate:  ;
        --tw-backdrop-sepia:  ;
}
.\!container {
        width: 100% !important;
}
.container {
        width: 100%;
}
@media (min-width: 640px) {
        .\!container {
                max-width: 640px !important;
        }
        .container {
                max-width: 640px;
        }
}
@media (min-width: 768px) {
        .\!container {
                max-width: 768px !important;
        }
        .container {
                max-width: 768px;
        }
}
@media (min-width: 1024px) {
        .\!container {
                max-width: 1024px !important;
        }
        .container {
                max-width: 1024px;
        }
}
@media (min-width: 1280px) {
        .\!container {
                max-width: 1280px !important;
        }
        .container {
                max-width: 1280px;
        }
}
@media (min-width: 1536px) {
        .\!container {
                max-width: 1536px !important;
        }
        .container {
                max-width: 1536px;
        }
}
.pointer-events-none {
        pointer-events: none;
}
.pointer-events-auto {
        pointer-events: auto;
}
.\!visible {
        visibility: visible !important;
}
.visible {
        visibility: visible;
}
.invisible {
        visibility: hidden;
}
.collapse {
        visibility: collapse;
}
.static {
        position: static;
}
.fixed {
        position: fixed;
}
.absolute {
        position: absolute;
}
.\!relative {
        position: relative !important;
}
.relative {
        position: relative;
}
.sticky {
        position: sticky;
}
.\!inset-0 {
        inset: 0px !important;
}
.bottom-full {
        bottom: 100%;
}
.left-0 {
        left: 0px;
}
.left-full {
        left: 100%;
}
.right-0 {
        right: 0px;
}
.right-1 {
        right: 0.25rem;
}
.right-full {
        right: 100%;
}
.top-0 {
        top: 0px;
}
.top-1 {
        top: 0.25rem;
}
.top-1\/2 {
        top: 50%;
}
.top-full {
        top: 100%;
}
.isolate {
        isolation: isolate;
}
.z-10 {
        z-index: 10;
}
.z-50 {
        z-index: 50;
}
.z-\[1000\] {
        z-index: 1000;
}
.z-\[5\] {
        z-index: 5;
}
.\!m-0 {
        margin: 0px !important;
}
.m-0 {
        margin: 0px;
}
.m-40 {
        margin: 10rem;
}
.m-44 {
        margin: 11rem;
}
.m-52 {
        margin: 13rem;
}
.m-56 {
        margin: 14rem;
}
.-mx-6 {
        margin-left: -1.5rem;
        margin-right: -1.5rem;
}
.-mb-1 {
        margin-bottom: -0.25rem;
}
.-ml-lg {
        margin-left: -1.25rem;
}
.-mr-lg {
        margin-right: -1.25rem;
}
.-mt-px {
        margin-top: -1px;
}
.ml-12 {
        margin-left: 3rem;
}
.mr-xxxs {
        margin-right: 0.375rem;
}
.mt-lg {
        margin-top: 1.25rem;
}
.line-clamp-3 {
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
}
.block {
        display: block;
}
.inline-block {
        display: inline-block;
}
.inline {
        display: inline;
}
.flex {
        display: flex;
}
.inline-flex {
        display: inline-flex;
}
.table {
        display: table;
}
.grid {
        display: grid;
}
.contents {
        display: contents;
}
.\!hidden {
        display: none !important;
}
.hidden {
        display: none;
}
.aspect-\[4\/3\] {
        aspect-ratio: 4/3;
}
.\!h-auto {
        height: auto !important;
}
.h-0 {
        height: 0px;
}
.h-1 {
        height: 0.25rem;
}
.h-3 {
        height: 0.75rem;
}
.h-6 {
        height: 1.5rem;
}
.h-80 {
        height: 20rem;
}
.h-auto {
        height: auto;
}
.h-full {
        height: 100%;
}
.h-screen {
        height: 100vh;
}
.min-h-0 {
        min-height: 0px;
}
.min-h-screen {
        min-height: 100vh;
}
.\!w-auto {
        width: auto !important;
}
.w-0 {
        width: 0px;
}
.w-32 {
        width: 8rem;
}
.w-4 {
        width: 1rem;
}
.w-full {
        width: 100%;
}
.w-screen {
        width: 100vw;
}
.flex-1 {
        flex: 1 1 0%;
}
.flex-auto {
        flex: 1 1 auto;
}
.flex-shrink {
        flex-shrink: 1;
}
.shrink {
        flex-shrink: 1;
}
.shrink-0 {
        flex-shrink: 0;
}
.flex-grow {
        flex-grow: 1;
}
.grow {
        flex-grow: 1;
}
.border-collapse {
        border-collapse: collapse;
}
.-translate-y-1\/2 {
        --tw-translate-y: -50%;
        transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.translate-x-1 {
        --tw-translate-x: 0.25rem;
        transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.translate-y-1 {
        --tw-translate-y: 0.25rem;
        transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.rotate-180 {
        --tw-rotate: 180deg;
        transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.scale-105 {
        --tw-scale-x: 1.05;
        --tw-scale-y: 1.05;
        transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.scale-125 {
        --tw-scale-x: 1.25;
        --tw-scale-y: 1.25;
        transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.scale-\[0\.6\] {
        --tw-scale-x: 0.6;
        --tw-scale-y: 0.6;
        transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.\!transform {
        transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}
.transform {
        transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.\!transform-none {
        transform: none !important;
}
.cursor-pointer {
        cursor: pointer;
}
.resize {
        resize: both;
}
.flex-row-reverse {
        flex-direction: row-reverse;
}
.flex-col {
        flex-direction: column;
}
.flex-wrap {
        flex-wrap: wrap;
}
.justify-end {
        justify-content: flex-end;
}
.justify-center {
        justify-content: center;
}
.gap-xxs {
        gap: 0.5rem;
}
.self-stretch {
        align-self: stretch;
}
.overflow-hidden {
        overflow: hidden;
}
.truncate {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
}
.whitespace-normal {
        white-space: normal;
}
.whitespace-nowrap {
        white-space: nowrap;
}
.whitespace-pre {
        white-space: pre;
}
.break-all {
        word-break: break-all;
}
.\!rounded-full {
        border-radius: 9999px !important;
}
.rounded {
        border-radius: 0.5rem;
}
.rounded-md {
        border-radius: 0.5rem;
}
.rounded-none {
        border-radius: 0px;
}
.rounded-sm {
        border-radius: 0.25rem;
}
.rounded-l-md {
        border-top-left-radius: 0.5rem;
        border-bottom-left-radius: 0.5rem;
}
.\!border {
        border-width: 1px !important;
}
.\!border-1 {
        border-width: 0.0625rem !important;
}
.border {
        border-width: 1px;
}
.border-0 {
        border-width: 0px;
}
.border-1 {
        border-width: 0.0625rem;
}
.border-b-1 {
        border-bottom-width: 0.0625rem;
}
.border-solid {
        border-style: solid;
}
.\!border-none {
        border-style: none !important;
}
.border-none {
        border-style: none;
}
.\!border-primary-500 {
        --tw-border-opacity: 1 !important;
        border-color: rgb(132 94 247 / var(--tw-border-opacity)) !important;
}
.border-dark-300 {
        --tw-border-opacity: 1;
        border-color: rgb(92 95 102 / var(--tw-border-opacity));
}
.border-gray-300 {
        --tw-border-opacity: 1;
        border-color: rgb(222 226 230 / var(--tw-border-opacity));
}
.border-gray-400 {
        --tw-border-opacity: 1;
        border-color: rgb(206 212 218 / var(--tw-border-opacity));
}
.bg-gray-100 {
        --tw-bg-opacity: 1;
        background-color: rgb(241 243 245 / var(--tw-bg-opacity));
}
.bg-primary {
        --tw-bg-opacity: 1;
        background-color: rgb(121 80 242 / var(--tw-bg-opacity));
}
.bg-primary-50 {
        --tw-bg-opacity: 1;
        background-color: rgb(243 240 255 / var(--tw-bg-opacity));
}
.bg-transparent {
        background-color: transparent;
}
.bg-white {
        --tw-bg-opacity: 1;
        background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}
.bg-clip-text {
        -webkit-background-clip: text;
                background-clip: text;
}
.object-cover {
        -o-object-fit: cover;
           object-fit: cover;
}
.p-0 {
        padding: 0px;
}
.p-sm {
        padding: 0.75rem;
}
.px-md {
        padding-left: 1rem;
        padding-right: 1rem;
}
.px-sm {
        padding-left: 0.75rem;
        padding-right: 0.75rem;
}
.px-xs {
        padding-left: 0.625rem;
        padding-right: 0.625rem;
}
.py-md {
        padding-top: 1rem;
        padding-bottom: 1rem;
}
.py-xl {
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
}
.py-xs {
        padding-top: 0.625rem;
        padding-bottom: 0.625rem;
}
.py-xxs {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
}
.py-xxxs {
        padding-top: 0.375rem;
        padding-bottom: 0.375rem;
}
.\!pr-lg {
        padding-right: 1.25rem !important;
}
.pb-xl {
        padding-bottom: 1.5rem;
}
.pl-lg {
        padding-left: 1.25rem;
}
.pl-sm {
        padding-left: 0.75rem;
}
.pr-lg {
        padding-right: 1.25rem;
}
.pt-xs {
        padding-top: 0.625rem;
}
.text-left {
        text-align: left;
}
.text-center {
        text-align: center;
}
.text-justify {
        text-align: justify;
}
.font-sans {
        font-family: DM Sans, sans-serif;
}
.text-\[0\.9em\] {
        font-size: 0.9em;
}
.text-sm {
        font-size: 0.875rem;
}
.text-xs {
        font-size: 0.75rem;
}
.uppercase {
        text-transform: uppercase;
}
.lowercase {
        text-transform: lowercase;
}
.capitalize {
        text-transform: capitalize;
}
.italic {
        font-style: italic;
}
.ordinal {
        --tw-ordinal: ordinal;
        font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction);
}
.text-primary {
        --tw-text-opacity: 1;
        color: rgb(121 80 242 / var(--tw-text-opacity));
}
.text-primary-100 {
        --tw-text-opacity: 1;
        color: rgb(229 219 255 / var(--tw-text-opacity));
}
.text-primary-200 {
        --tw-text-opacity: 1;
        color: rgb(208 191 255 / var(--tw-text-opacity));
}
.text-yellow-800 {
        --tw-text-opacity: 1;
        color: rgb(240 140 0 / var(--tw-text-opacity));
}
.underline {
        text-decoration-line: underline;
}
.overline {
        text-decoration-line: overline;
}
.line-through {
        text-decoration-line: line-through;
}
.antialiased {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
}
.shadow {
        --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
        --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
        box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-sm {
        --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
        --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
        box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-xs {
        --tw-shadow: 0 2px 3px -1px rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
        --tw-shadow-colored: 0 2px 3px -1px var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
        box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.outline {
        outline-style: solid;
}
.ring {
        --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
        --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
        box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}
.ring-2 {
        --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
        --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
        box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}
.ring-gray-400 {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(206 212 218 / var(--tw-ring-opacity));
}
.ring-offset-2 {
        --tw-ring-offset-width: 2px;
}
.ring-offset-white {
        --tw-ring-offset-color: #fff;
}
.blur {
        --tw-blur: blur(8px);
        filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.drop-shadow {
        --tw-drop-shadow: drop-shadow(0 1px 2px rgb(0 0 0 / 0.1)) drop-shadow(0 1px 1px rgb(0 0 0 / 0.06));
        filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.grayscale {
        --tw-grayscale: grayscale(100%);
        filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.invert {
        --tw-invert: invert(100%);
        filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.\!filter {
        filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
}
.filter {
        filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.\!transition {
        transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter !important;
        transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter !important;
        transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter !important;
        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
        transition-duration: 150ms !important;
}
.transition {
        transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
        transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
        transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
        transition-duration: 150ms;
}
.transition-colors {
        transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
        transition-duration: 150ms;
}
.transition-transform {
        transition-property: transform;
        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
        transition-duration: 150ms;
}
.ease-in {
        transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
}
.ease-in-out {
        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.ease-out {
        transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
}
.base-border {
        border: 0.0625rem solid var(--mantine-color-gray-3);
    }
.\!\[a-zA-Z\:_\] {
        a-z-a--z:  !important;
}

html {
    font-size: 20px;
    min-height: 100vh;
}

html,
body {
    padding: 0;
    margin: 0;
    font-family: var(--mantine-font-family);
    scroll-behavior: smooth;
    scroll-padding-top: 4rem;
}

a {
    color: inherit;
    text-decoration: none;
}

p {
    margin: 0;
}

.rubik {
    font-family: 'Rubik', sans-serif !important;
}

.ofv {
    overflow: visible !important;
}

.nosel {
    -webkit-user-select: none !important;
       -moz-user-select: none !important;
            user-select: none !important;
}

.flg {
    flex-grow: 1;
}

.mantine-Switch-track {
    cursor: pointer;
}

/* @media (prefers-color-scheme: dark) {
    html {
        color-scheme: dark;
    }

    body {
        color: white;
        background: black;
    }
} */

/* .hoverScale {
    transform: scale(1.01);
} */

.placeholder\:text-gray-500::-moz-placeholder {
        --tw-text-opacity: 1;
        color: rgb(173 181 189 / var(--tw-text-opacity));
}

.placeholder\:text-gray-500::placeholder {
        --tw-text-opacity: 1;
        color: rgb(173 181 189 / var(--tw-text-opacity));
}

.placeholder\:opacity-50::-moz-placeholder {
        opacity: 0.5;
}

.placeholder\:opacity-50::placeholder {
        opacity: 0.5;
}

.last\:hidden:last-child {
        display: none;
}

.last\:rounded-r-md:last-child {
        border-top-right-radius: 0.5rem;
        border-bottom-right-radius: 0.5rem;
}

.last\:border-b-0:last-child {
        border-bottom-width: 0px;
}

.even\:self-end:nth-child(even) {
        align-self: flex-end;
}

.hover\:scale-105:hover {
        --tw-scale-x: 1.05;
        --tw-scale-y: 1.05;
        transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.hover\:scale-125:hover {
        --tw-scale-x: 1.25;
        --tw-scale-y: 1.25;
        transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.hover\:bg-gray-50:hover {
        --tw-bg-opacity: 1;
        background-color: rgb(248 249 250 / var(--tw-bg-opacity));
}

.hover\:bg-white:hover {
        --tw-bg-opacity: 1;
        background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.hover\:text-dark:hover {
        --tw-text-opacity: 1;
        color: rgb(37 38 43 / var(--tw-text-opacity));
}

.hover\:text-primary:hover {
        --tw-text-opacity: 1;
        color: rgb(121 80 242 / var(--tw-text-opacity));
}

.hover\:text-white:hover {
        --tw-text-opacity: 1;
        color: rgb(255 255 255 / var(--tw-text-opacity));
}

.hover\:base-border:hover {
        border: 0.0625rem solid var(--mantine-color-gray-3);
    }

.focus\:border-primary:focus {
        --tw-border-opacity: 1;
        border-color: rgb(121 80 242 / var(--tw-border-opacity));
}

.focus\:outline-none:focus {
        outline: 2px solid transparent;
        outline-offset: 2px;
}

.active\:scale-110:active {
        --tw-scale-x: 1.1;
        --tw-scale-y: 1.1;
        transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.active\:scale-95:active {
        --tw-scale-x: .95;
        --tw-scale-y: .95;
        transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@media not all and (min-width: 640px) {
        .max-sm\:\!border-x-0 {
                border-left-width: 0px !important;
                border-right-width: 0px !important;
        }
}

@media (min-width: 640px) {
        .sm\:m-0 {
                margin: 0px;
        }
        .sm\:mt-\[-2\.5rem\] {
                margin-top: -2.5rem;
        }
        .sm\:block {
                display: block;
        }
        .sm\:hidden {
                display: none;
        }
        .sm\:h-60 {
                height: 15rem;
        }
        .sm\:w-3\/5 {
                width: 60%;
        }
        .sm\:w-4\/5 {
                width: 80%;
        }
        .sm\:w-6 {
                width: 1.5rem;
        }
        .sm\:rounded-md {
                border-radius: 0.5rem;
        }
        .sm\:p-lg {
                padding: 1.25rem;
        }
        .sm\:px-16 {
                padding-left: 4rem;
                padding-right: 4rem;
        }
        .sm\:px-xl {
                padding-left: 1.5rem;
                padding-right: 1.5rem;
        }
        .sm\:py-8 {
                padding-top: 2rem;
                padding-bottom: 2rem;
        }
        .sm\:text-\[1em\] {
                font-size: 1em;
        }
        .sm\:shadow-xs {
                --tw-shadow: 0 2px 3px -1px rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
                --tw-shadow-colored: 0 2px 3px -1px var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
                box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
        }
    .sm\:base-border {
        border: 0.0625rem solid var(--mantine-color-gray-3);
    }
}

@media (min-width: 768px) {
        .md\:top-\[30px\] {
                top: 30px;
        }
        .md\:ml-\[-30px\] {
                margin-left: -30px;
        }
        .md\:block {
                display: block;
        }
        .md\:hidden {
                display: none;
        }
        .md\:w-56 {
                width: 14rem;
        }
        .md\:flex-1 {
                flex: 1 1 0%;
        }
}

@media (min-width: 1024px) {
        .lg\:absolute {
                position: absolute;
        }
        .lg\:right-5 {
                right: 1.25rem;
        }
        .lg\:top-5 {
                top: 1.25rem;
        }
        .lg\:mt-0 {
                margin-top: 0px;
        }
        .lg\:block {
                display: block;
        }
        .lg\:hidden {
                display: none;
        }
}

.\[\&\>div\]\:h-full>div {
        height: 100%;
}

.\[\&\>div\]\:w-full>div {
        width: 100%;
}
pre code.hljs{display:block;overflow-x:auto;padding:1em}code.hljs{padding:3px 5px}/*!
  Theme: GitHub
  Description: Light theme as seen on github.com
  Author: github.com
  Maintainer: @Hirse
  Updated: 2021-05-15

  Outdated base version: https://github.com/primer/github-syntax-light
  Current colors taken from GitHub's CSS
*/.hljs{color:#24292e;background:#fff}.hljs-doctag,.hljs-keyword,.hljs-meta .hljs-keyword,.hljs-template-tag,.hljs-template-variable,.hljs-type,.hljs-variable.language_{color:#d73a49}.hljs-title,.hljs-title.class_,.hljs-title.class_.inherited__,.hljs-title.function_{color:#6f42c1}.hljs-attr,.hljs-attribute,.hljs-literal,.hljs-meta,.hljs-number,.hljs-operator,.hljs-selector-attr,.hljs-selector-class,.hljs-selector-id,.hljs-variable{color:#005cc5}.hljs-meta .hljs-string,.hljs-regexp,.hljs-string{color:#032f62}.hljs-built_in,.hljs-symbol{color:#e36209}.hljs-code,.hljs-comment,.hljs-formula{color:#6a737d}.hljs-name,.hljs-quote,.hljs-selector-pseudo,.hljs-selector-tag{color:#22863a}.hljs-subst{color:#24292e}.hljs-section{color:#005cc5;font-weight:700}.hljs-bullet{color:#735c0f}.hljs-emphasis{color:#24292e;font-style:italic}.hljs-strong{color:#24292e;font-weight:700}.hljs-addition{color:#22863a;background-color:#f0fff4}.hljs-deletion{color:#b31d28;background-color:#ffeef0}
.hljs-regex-class {
    color: var(--mantine-color-blue-6);
}

.hljs-regex-set {
    color: var(--mantine-color-yellow-8);
}

.hljs-regex-grouping {
    color: var(--mantine-color-green-6);
}

.hljs-regex-anchor {
    color: var(--mantine-color-orange-6);
}

.hljs-regex-quantifier {
    color: var(--mantine-color-grape-6);
}

.hljs-regex-escape {
    color: var(--mantine-color-red-6);
}
.RegexControl_editor__qjd3a {
    border: 1px solid var(--mantine-color-gray-3);
    border-radius: var(--mantine-radius-md);
    flex-grow: 1;
    background: white;
}
/* CSS variables. */
:root {
	--PhoneInput-color--focus: #03b2cb;
	--PhoneInputInternationalIconPhone-opacity: 0.8;
	--PhoneInputInternationalIconGlobe-opacity: 0.65;
	--PhoneInputCountrySelect-marginRight: 0.35em;
	--PhoneInputCountrySelectArrow-width: 0.3em;
	--PhoneInputCountrySelectArrow-marginLeft: var(--PhoneInputCountrySelect-marginRight);
	--PhoneInputCountrySelectArrow-borderWidth: 1px;
	--PhoneInputCountrySelectArrow-opacity: 0.45;
	--PhoneInputCountrySelectArrow-color: currentColor;
	--PhoneInputCountrySelectArrow-color--focus: var(--PhoneInput-color--focus);
	--PhoneInputCountrySelectArrow-transform: rotate(45deg);
	--PhoneInputCountryFlag-aspectRatio: 1.5;
	--PhoneInputCountryFlag-height: 1em;
	--PhoneInputCountryFlag-borderWidth: 1px;
	--PhoneInputCountryFlag-borderColor: rgba(0,0,0,0.5);
	--PhoneInputCountryFlag-borderColor--focus: var(--PhoneInput-color--focus);
	--PhoneInputCountryFlag-backgroundColor--loading: rgba(0,0,0,0.1);
}

.PhoneInput {
	/* This is done to stretch the contents of this component. */
	display: flex;
	align-items: center;
}

.PhoneInputInput {
	/* The phone number input stretches to fill all empty space */
	flex: 1;
	/* The phone number input should shrink
	   to make room for the extension input */
	min-width: 0;
}

.PhoneInputCountryIcon {
	width: calc(var(--PhoneInputCountryFlag-height) * var(--PhoneInputCountryFlag-aspectRatio));
	height: var(--PhoneInputCountryFlag-height);
}

.PhoneInputCountryIcon--square {
	width: var(--PhoneInputCountryFlag-height);
}

.PhoneInputCountryIcon--border {
	/* Removed `background-color` because when an `<img/>` was still loading
	   it would show a dark gray rectangle. */
	/* For some reason the `<img/>` is not stretched to 100% width and height
	   and sometime there can be seen white pixels of the background at top and bottom. */
	background-color: var(--PhoneInputCountryFlag-backgroundColor--loading);
	/* Border is added via `box-shadow` because `border` interferes with `width`/`height`. */
	/* For some reason the `<img/>` is not stretched to 100% width and height
	   and sometime there can be seen white pixels of the background at top and bottom,
	   so an additional "inset" border is added. */
	box-shadow: 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor),
		inset 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor);
}

.PhoneInputCountryIconImg {
	/* Fixes weird vertical space above the flag icon. */
	/* https://gitlab.com/catamphetamine/react-phone-number-input/-/issues/7#note_348586559 */
	display: block;
	/* 3rd party <SVG/> flag icons won't stretch if they have `width` and `height`.
	   Also, if an <SVG/> icon's aspect ratio was different, it wouldn't fit too. */
	width: 100%;
	height: 100%;
}

.PhoneInputInternationalIconPhone {
	opacity: var(--PhoneInputInternationalIconPhone-opacity);
}

.PhoneInputInternationalIconGlobe {
	opacity: var(--PhoneInputInternationalIconGlobe-opacity);
}

/* Styling native country `<select/>`. */

.PhoneInputCountry {
	position: relative;
	align-self: stretch;
	display: flex;
	align-items: center;
	margin-right: var(--PhoneInputCountrySelect-marginRight);
}

.PhoneInputCountrySelect {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	z-index: 1;
	border: 0;
	opacity: 0;
	cursor: pointer;
}

.PhoneInputCountrySelect[disabled],
.PhoneInputCountrySelect[readonly] {
	cursor: default;
}

.PhoneInputCountrySelectArrow {
	display: block;
	content: '';
	width: var(--PhoneInputCountrySelectArrow-width);
	height: var(--PhoneInputCountrySelectArrow-width);
	margin-left: var(--PhoneInputCountrySelectArrow-marginLeft);
	border-style: solid;
	border-color: var(--PhoneInputCountrySelectArrow-color);
	border-top-width: 0;
	border-bottom-width: var(--PhoneInputCountrySelectArrow-borderWidth);
	border-left-width: 0;
	border-right-width: var(--PhoneInputCountrySelectArrow-borderWidth);
	transform: var(--PhoneInputCountrySelectArrow-transform);
	opacity: var(--PhoneInputCountrySelectArrow-opacity);
}

.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon + .PhoneInputCountrySelectArrow {
	opacity: 1;
	color: var(--PhoneInputCountrySelectArrow-color--focus);
}

.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon--border {
	box-shadow: 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor--focus),
		inset 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor--focus);
}

.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon .PhoneInputInternationalIconGlobe {
	opacity: 1;
	color: var(--PhoneInputCountrySelectArrow-color--focus);
}
.NodePalette_tile__wKJvY {
    display: flex;
    flex-direction: column;
    align-items: stretch;

    padding: var(--mantine-spacing-md);

    border: 2px solid var(--mantine-color-gray-1);
    border-radius: var(--mantine-radius-md);
    transition: background-color 0.1s, border-color 0.1s;
    cursor: pointer;
}

.NodePalette_tile__wKJvY:hover,
.NodePalette_tile__wKJvY:focus {
    background-color: var(--bgColor);
    border-color: var(--mainColor);
}

.NodePalette_tile__wKJvY.NodePalette_expanded__TqAcU {
    padding: var(--mantine-spacing-md) var(--mantine-spacing-xl);
}

.NodePalette_cartQuantity__S8XLe {
    opacity: 0.3 !important;
    transition: opacity 0.1s;
}

.NodePalette_cartQuantity__S8XLe:hover,
.NodePalette_cartQuantity__S8XLe.NodePalette_notEmpty__3upu0 {
    opacity: 1 !important;
}

.NodePalette_pin__CNE39 {
    opacity: 0.3;
    transition: opacity 0.1s;
}

.NodePalette_pin__CNE39:hover {
    opacity: 1;
}
.DataViewer_hideChevron__fR6bk .DataViewer_chevron__ir_mW {
    visibility: hidden;
}

.DataViewer_label__w2AFU {
    padding: 6px var(--mantine-spacing-xs);
    /* padding: 4px; */
}

.DataViewer_content__SmN2J {
    padding-bottom: 0;
}

.DataViewer_item__Tv_A2 {
    border-bottom: none;
}

.DataViewer_item__Tv_A2:not(.DataViewer_topLevel___1MjV) {
    border-left: 1px solid var(--mantine-color-gray-4);
}

.DataViewer_control__4SxOT {
    padding-left: 5px;
}

.DataViewer_onlyCode__c1Dp1 {
    font-size: var(--mantine-font-size-lg);
}
